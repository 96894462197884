import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: palette.background.default,
    flexWrap: 'wrap',
    '& > h1': {
      marginBottom: spacing(8.75),
    },
    '& img': {
      height: 'auto',
      width: 'auto',
      [breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    '& h3': {
      fontSize: 20,
      padding: spacing(0, 5, 0, 5),
      width: '-webkit-fill-available',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [breakpoints.down('xs')]: {
      textAlign: 'center',
      '& h3': {
        marginTop: spacing(3),
      },
    },
  },
  cardsContainer: {
    justifyContent: 'start',
  },
  card: {
    [breakpoints.up('md')]: {
      width: 'calc(100% / 3)',
    },
    marginBottom: spacing(5),
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: 'fit-content',
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: 70,
  },
}))

export default useStyles
