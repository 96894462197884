import * as R from 'ramda'
import {shape} from 'prop-types'
import React from 'react'

import {kpisPropTypes} from 'helpers/propTypes'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const Kpis = ({data}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.container}>
        <div className={classes.container}>
          {R.map(
            content => (
              <div className={classes.card}>
                <div className={classes.imgWrap}>
                  <Media data={content.icon} className={classes.icon} />
                </div>
                <Title variant="h3" type="innerTitle">
                  {content.fullSentence}
                </Title>
              </div>
            ),
            data,
          )}
        </div>
      </div>
    </Section>
  )
}

Kpis.propTypes = kpisPropTypes

Kpis.defaultProps = shape({
  data: null,
  title: '',
})

export default Kpis
